import React from "react";
import { Box, Button, Container, Divider, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface IProps {
	title: string;
	summary: string;
	storeUrl: string;
}

export const ProductTitle = (props: IProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Box
			as="section"
			bg="bg-surface"
			pt={{ base: "4", md: "8" }}
			pb={{ base: "6", md: "6" }}
		>
			<Container>
				<Stack spacing="5">
					<Stack
						spacing="4"
						direction={{ base: "column", sm: "row" }}
						justify="space-between"
					>
						<Box>
							<Text fontSize="lg" fontWeight="medium">
								{props.title}
							</Text>
							<Text color="muted" fontSize="sm">
								{props.summary}
							</Text>
						</Box>
						<Button variant="primary" alignSelf="start">
							<a href={props.storeUrl}>
								{t("productDetails.getButtonContent")}
							</a>
						</Button>
					</Stack>
					<Divider />
				</Stack>
			</Container>
		</Box>
	);
};
