import React from "react";
import { Icon } from "@chakra-ui/icons";
import {
	Divider,
	Flex,
	Image,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
} from "@chakra-ui/react";
import {
	FiHelpCircle,
	FiSearch,
	FiSettings,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import { Logo } from "../logo";
import { NavButton } from "./NavButton";
import { UserProfile } from "./UserProfile";
import { useAppState, useAppStateDispatch, setCurrentNav } from "../../contexts/appState";
import { buttons, IButtonInfo } from "./navBarButtons";

export const Sidebar = () => {
	const appState = useAppState();
	const appStateDispatch = useAppStateDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const doNavigation = (b: IButtonInfo) => {
		navigate(b.path);
		appStateDispatch(setCurrentNav(b.id));
	}
	const renderButton = (b: IButtonInfo): JSX.Element => {
		if (b.id === appState.currentNav) {
			return (
				<NavButton
					key={b.id}
					label={b.content}
					icon={b.icon}
					aria-current="page"
					onClick={() => doNavigation(b)}
				/>
			);
		}

		return (
			<NavButton
				key={b.id}
				label={b.content}
				icon={b.icon}
				onClick={() => doNavigation(b)}
			/>
		);
	};

	return (
		<Flex as="section" minH="100vh" bg="bg-canvas">
			<Flex
				flex="1"
				bg="bg-accent"
				color="on-accent"
				maxW={{ base: "full", sm: "xs" }}
				py={{ base: "6", sm: "8" }}
				px={{ base: "4", sm: "6" }}
			>
				<Stack justify="space-between" spacing="1">
					<Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
						{/* <Logo /> */}
						<Image src="/images/bbsw-logo.png" />
						<InputGroup>
							<InputLeftElement pointerEvents="none">
								<Icon as={FiSearch} color="on-accent" boxSize="5" />
							</InputLeftElement>
							<Input placeholder="Search" variant="filled" colorScheme="blue" />
						</InputGroup>
						<Stack spacing="1">
							{
								buttons.map(b => renderButton(b))
							}
						</Stack>
					</Stack>
					<Stack spacing={{ base: "5", sm: "6" }}>
						<Stack spacing="1">
							<NavButton label="Help" icon={FiHelpCircle} />
							<NavButton label="Settings" icon={FiSettings} />
						</Stack>
						<Divider />
						<UserProfile
							name="Christoph Winston"
							image="https://tinyurl.com/yhkm2ek8"
							email="chris@chakra-ui.com"
						/>
					</Stack>
				</Stack>
			</Flex>
		</Flex>
	);
};
