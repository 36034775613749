import {
	Avatar,
	Box,
	Button,
	HStack,
	Icon,
	Stack,
	Tag,
	Text,
	useColorModeValue,
	Wrap,
} from "@chakra-ui/react";
import * as React from "react";
import { HiCash, HiLocationMarker, HiShieldCheck } from "react-icons/hi";
import { Card } from "./card";
import { CustomerReviews } from "./customerReviews";
import { useNavigate } from "react-router-dom";
import { IProduct } from "../../models/IProduct";

export interface IProps extends IProduct {
}

export const testProps: IProps = {
	id: 99,
	name: "Your BOM",
	summary: "Custom BOM reporting tool",
	description:
		"Export your BOM from Inventor in your own custom format to be consumed by your downstream users. Have seperate report formats for different uses.",
	imageUrl:
		"https://images.unsplash.com/photo-1542103749-8ef59b94f47e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjY5fHxsYWR5JTIwc21pbGluZ3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
	storeUrl: "https://www.brainbucketsoftware.com",
	actionButtonContent: "STORE",
	detailsUrl: "/productDetails/13",
	detailsUrlContent: "details",
	reviewAverage: 4.5,
	reviewCount: 6,
	price: "$1.50 per month",
	location: "North Vancouver",
	tags: ["Inventor", "Excel", "2020", "2021", "2022", "2023", "BOM", "AWESOME"],
	contactLink: "mailto:info@brainbucketsoftware.com",
};

export const ProductCard = (props: IProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Box
			as="section"
			bg={useColorModeValue("gray.100", "gray.800")}
			py="4"
			px="4"
		>
			<Card>
				<Stack
					direction={{ base: "column", md: "row" }}
					spacing={{ base: "3", md: "8" }}
					align="flex-start"
				>
					<Stack spacing="4">
						<Avatar size="2xl" src={props.imageUrl} name={props.name} />
						<Button
							width="full"
							colorScheme="blue"
							display={{ base: "none", md: "initial" }}
						>
							<a href={props.storeUrl}>
								{props.actionButtonContent}
							</a>
						</Button>
					</Stack>
					<Box>
						<Stack
							spacing={{ base: "1", md: "2" }}
							direction={{ base: "column", md: "row" }}
						>
							<Text as="h2" fontWeight="bold" fontSize="xl">
								{props.name}
							</Text>
							<HStack fontSize={{ base: "md", md: "lg" }}>
								<Text
									as="a"
									href={props.detailsUrl}
									color={useColorModeValue("gray.500", "gray.300")}
									lineHeight="1"
								>
									{props.detailsUrlContent}
								</Text>
								<Icon as={HiShieldCheck} color="green.500" />
							</HStack>
						</Stack>
						<Text mt="2">{props.summary}</Text>
						<Wrap shouldWrapChildren my="4" spacing="4">
							<CustomerReviews
								reviewCount={props.reviewCount}
								rating={props.reviewAverage}
							/>
							<HStack>
								<Icon as={HiCash} fontSize="xl" color="gray.400" />
								<Text
									fontSize="sm"
									fontWeight="medium"
									color={useColorModeValue("gray.600", "gray.300")}
								>
									<b>{props.price}</b>
								</Text>
							</HStack>

							<HStack spacing="1">
								<Icon as={HiLocationMarker} color="gray.400" />
								<Text
									fontSize="sm"
									fontWeight="medium"
									color={useColorModeValue("gray.600", "gray.300")}
								>
									{props.location}
								</Text>
							</HStack>
						</Wrap>
						<Box fontSize="sm" noOfLines={2}>
							{props.description}
						</Box>
						<Wrap
							shouldWrapChildren
							mt="5"
							color={useColorModeValue("gray.600", "gray.300")}
						>
							{props.tags.map((t) => (
								<Tag key={t} color="inherit" px="3">
									{t}
								</Tag>
							))}
						</Wrap>
					</Box>
				</Stack>
				<Button
					as="a"
					href={props.contactLink}
					mt="8"
					width="full"
					colorScheme="blue"
					display={{ md: "none" }}
				>
					Contact me
				</Button>
			</Card>
		</Box>
	);
};
