import React from "react";
import {
	Box,
	BoxProps,
	Image,
	useColorModeValue,
} from "@chakra-ui/react";


interface IProps extends BoxProps {
	src?: any;
	alt?: string;
}

export const Card = (props: IProps): JSX.Element => (
	<Box
		minH="36"
		bg="bg-surface"
		boxShadow={useColorModeValue("sm", "sm-dark")}
		borderRadius="lg"
		{...props}
	>
		<Image src={props.src} alt={props.alt} />
	</Box>
);
