import * as React from "react";
import {
	Heading,
	SimpleGrid,
	Stack,
	Text,
	useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Card } from "./card";


export const Content = () => {
	const { t } = useTranslation();

	return (
		<Stack spacing={{ base: "8", lg: "8" }}>
			<Stack
				spacing="4"
				direction={{ base: "column", lg: "row" }}
				justify="space-between"
			>
				<Stack spacing="1">
					<Heading
						size={useBreakpointValue({ base: "xs", lg: "sm" })}
						fontWeight="medium"
					>
						{t("home.pageHeading")}
					</Heading>
					<Text color="muted">{t("home.subHeading")}</Text>
				</Stack>
			</Stack>
			<Stack spacing={{ base: "5", lg: "6" }}>
				<SimpleGrid columns={{ base: 1, md: 3 }} gap="6">
					<Card
						src={process.env.PUBLIC_URL + "/images/yourBom/B2EMainWindow.jpg"}
						p="3"
						alt={t("home.b2yMainWindow")}
					/>
					<Card
						src={process.env.PUBLIC_URL + "/images/properdizer/MainWindow.png"}
						p="3"
						alt={t("home.properdizer")}
					/>
					<Card
						src={process.env.PUBLIC_URL + "/images/partsListEditor/EditedValue.PNG"}
						p="3"
						alt={t("home.partsListEditor")}
					/>
				</SimpleGrid>
			</Stack>
			<Card minH="xs"
				src={process.env.PUBLIC_URL + "/images/mmc/Export-to-excel.png"}
				alt={t("home.megaScreenShot")}
				p="3"
			/>
		</Stack>
	);
};

