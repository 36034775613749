import React from "react";
import {
	FiBookmark,
	FiCheckSquare,
	FiHome,
	FiInfo,
	FiUsers,
} from "react-icons/fi";

import i18n from "../../i18n";

export interface IButtonInfo {
	content: string;
	id: string;
	path: string;
	isAdmin?: boolean;
	icon: any;
}

export const buttons: IButtonInfo[] = [
	{
		content: i18n.t("navHome"),
		id: "home",
		path: "/",
		icon: FiHome
	},
	{
		content: i18n.t("navProducts"),
		id: "products",
		path: "/products",
		icon: FiBookmark,
	},
	{
		content: i18n.t("navServices"),
		id: "tasks",
		path: "/services",
		icon: FiCheckSquare,
	},
	{
		content: i18n.t("navUsers"),
		id: "users",
		path: "/users",
		isAdmin: true,
		icon: FiUsers,
	},
	{
		content: i18n.t("navAbout"),
		id: "about",
		path: "/about",
		isAdmin: false,
		icon: FiInfo,
	},
];
