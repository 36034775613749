import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppStateProvider } from "./contexts/appState";

import "./i18n";


const configuration: Configuration = {
	auth: {
		clientId: "61639bfc-43da-4c3e-b397-59add992b2fa",
		redirectUri: "/",
		postLogoutRedirectUri: "/",
	},
	cache: {
		cacheLocation: "localStorage",
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				console.log(`${level}: contains Pii: ${containsPii}`);
				console.log(message);
			},
		}
	}
};
export const msalInstance = new PublicClientApplication(configuration);
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
msalInstance.enableAccountStorageEvents();

ReactDOM.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<BrowserRouter>
				<AppStateProvider>
					<App />
				</AppStateProvider>
			</BrowserRouter>
		</MsalProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
