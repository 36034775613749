import React from "react";

import { Layout } from "../../components/layout";
import { useTranslation } from "react-i18next";


export const AutodeskPrivacyPage = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Layout title="autodeskAppPrivacy" containerWidth="80%">
			<div>
				<h1>{t("autodeskAppPrivacy.privacyTitle")}</h1>
				<p>
					{t("autodeskAppPrivacy.privacyStatement")}
				</p>
			</div>
		</Layout>
	);
};
