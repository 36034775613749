import React, { Dispatch, createContext, useReducer, useContext } from "react";
import { initialAppState, appStateReducer } from "./reducer";
import { AppState } from "./appState";
import { AppStateAction } from "./appStateActions";

const StateContext = createContext<AppState | undefined>(undefined);
const DispatchContext = createContext<Dispatch<AppStateAction> | undefined>(undefined);


interface IProps {
	children: JSX.Element;
	state?: AppState;
}

export const AppStateProvider = (props: IProps): JSX.Element => {
	const [ state, dispatch ] = useReducer(appStateReducer, props.state || initialAppState);

	return (
		<StateContext.Provider value={state}>
			<DispatchContext.Provider value={dispatch}>
				{props.children}
			</DispatchContext.Provider>
		</StateContext.Provider>
	);
};

export const useAppState = (): AppState => {
	const context = useContext(StateContext);
	if (context === undefined) { throw new Error("AppStateProvider"); }
	return context;
};

export const useAppStateDispatch = (): Dispatch<AppStateAction> => {
	const context = useContext(DispatchContext);
	if (context === undefined) { throw new Error("AppStateProvider"); }
	return context;
};
