import React, { useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";

import { AuthLoading } from "./authLoading";
import { AuthError } from "./authError";
import { msalInstance } from "..";
import { useAppState, useAppStateDispatch, setGraphData } from "../contexts/appState";

const authRequest = {
	scopes: [ "openid", "User.Read" ],
};
const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};

export async function callMsGraph(accessToken: any) {
	const accounts = msalInstance.getAllAccounts();
	if (accounts.length > 0) {
		msalInstance.setActiveAccount(accounts[0]);
	}
	if (!accessToken) {
		const account = msalInstance.getActiveAccount();
		if (!account) {
			throw Error("No active account");
		}

		const response = await msalInstance.acquireTokenSilent({
			...authRequest,
			account: account,
		});
		accessToken = response.accessToken;
	}

	const headers = new Headers();
	const bearer = `bearer ${accessToken}`;
	headers.append("Authorization", bearer);
	const options = {
		method: "GET",
		headers: headers,
	};

	return fetch(graphConfig.graphMeEndpoint, options)
		.then(response => response.json())
		.catch(error => console.log(error));
}

interface IGuardProps {
	children?: React.ReactNode;
}

export const Guard = (props: IGuardProps): JSX.Element => {
	const { instance, inProgress } = useMsal();
	const { graphData } = useAppState();
	const dispatch = useAppStateDispatch();

	// useEffect(() => {
	// 	if (!graphData && inProgress === InteractionStatus.None) {
	// 		callMsGraph(null).then(response => {
	// 			console.log(response);
	// 			dispatch(setGraphData(response));
	// 		}).catch((e) => {
	// 			if (e instanceof InteractionRequiredAuthError) {
	// 				const account = instance.getActiveAccount() ?? undefined;
	// 				instance.acquireTokenRedirect({
	// 					...authRequest,
	// 					account,
	// 				});
	// 			}
	// 		});
	// 	}

	// }, [ inProgress, graphData, instance, dispatch ]);

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest}
			errorComponent={AuthError}
			loadingComponent={AuthLoading}
		>
			{props.children}
		</MsalAuthenticationTemplate>
	);

}