import React from "react";
import { Stack } from "@chakra-ui/react";

import { Layout } from "../../components/layout";
import { ProductCard } from "../../components/productCard";
import { products } from "./products";

export const ProductPage = (): JSX.Element => {
	return (
		<Layout title="products">
			<Stack>
				{
					products.map((p, index) => (<ProductCard {...p} key={p.id} />))
				}
			</Stack>
		</Layout>
	);
};
