import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";

import "@fontsource/inter/variable.css";

import { HomePage } from "./pages/home";
import { ProductDetailsPage } from "./pages/productDetails";
import { ProductPage } from "./pages/products";
import { ServicesPage } from "./pages/services";
import { AboutPage } from "./pages/about";
import { UsersPage } from "./pages/users";
import { LoginPage } from "./pages/login";
import { AutodeskPrivacyPage } from "./pages/autodeskPrivacy";

export const App = (): JSX.Element => {
	const myTheme = extendTheme(
		{
			colors: { ...theme.colors, brand: theme.colors.blue },
		},
		theme,
	);

	return (
		<ChakraProvider theme={myTheme}>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/products" element={<ProductPage />} />
				<Route path="/services" element={<ServicesPage />} />
				<Route path="/about" element={<AboutPage />} />
				<Route path="/users" element={<UsersPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/productDetails/:productId" element={<ProductDetailsPage />} />
				<Route path="/home/autodeskAppPrivacy" element={<AutodeskPrivacyPage />} />
			</Routes>
		</ChakraProvider>
	);
};
