import React from "react";
import { Box, Container } from "@chakra-ui/react";
import { Navbar } from "./Navbar";
import { Footer } from "../footer";

interface IProps {
	title: string;
	children: JSX.Element;
	containerWidth?: any;
}

export const Layout = (props: IProps) => (
	<Box as="section" height="100vh" overflowY="auto">
		<Navbar />
		<Container maxWidth={props.containerWidth} pt={{ base: "8", lg: "12" }} pb={{ base: "12", lg: "24" }}>
			{props.children}
		</Container>
		<Footer />
	</Box>
);
