import React from "react";
import { useTranslation } from "react-i18next";
import { Heading, Text } from "@chakra-ui/react";

import { Layout } from "../../components/layout";


export const ServicesPage = (): JSX.Element => {
	const { t } = useTranslation();
	
	return (
		<Layout title="Services">
			<div>
				<Heading as="h4">{t("services.title")}</Heading>
				<Text mb="2rem" mx="1rem">{t("services.servicesLine1")}</Text>
				<Text mx="1rem">{t("services.servicesLine2")}</Text>
			</div>
		</Layout>
	);
};
