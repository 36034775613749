import React from "react";
import {
	Box,
	Button,
	Heading,
	HStack,
	Link,
	Stack,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Gallery } from "./gallery";
import { Rating } from "./rating";
import { IProduct } from "../../models/IProduct";

interface IProps extends IProduct {

}

export const ProductDetails = (props: IProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Box
			maxW="7xl"
			mx="auto"
			px={{ base: "4", md: "8", lg: "12" }}
			py={{ base: "6", md: "8", lg: "12" }}
		>
			<Stack
				direction={{ base: "column-reverse", lg: "row" }}
				spacing={{ base: "6", lg: "12", xl: "16" }}
			>
				<Stack
					spacing={{ base: "6", lg: "8" }}
					maxW={{ lg: "sm" }}
					justify="center"
				>
					<Stack spacing={{ base: "3", md: "4" }}>
						<Stack spacing="3">
							<HStack alignSelf="baseline">
								<Rating defaultValue={4} size="sm" />
								<Link
									href="#"
									fontSize="sm"
									fontWeight="medium"
									color={useColorModeValue("gray.600", "gray.400")}
								>
									{`${props.reviewCount} ${t("productDetails.reviewsLabel")}`}
								</Link>
							</HStack>
							<Heading size="lg" fontWeight="medium">
								{props.name}
							</Heading>
						</Stack>
						<Text color={useColorModeValue("gray.600", "gray.400")}>
							{props.price}
						</Text>
						<Text color={useColorModeValue("gray.600", "gray.400")}>
							{props.description}
						</Text>
					</Stack>
					<Button colorScheme="blue" size="lg">
						{t("productDetails.getButtonContent")}
					</Button>
				</Stack>
				<Gallery
					rootProps={{ overflow: "hidden", flex: "1" }}
					images={props.galleryImages ?? []}
				/>
			</Stack>
		</Box>
	);
};
