export enum ActionTypes {
	SET_BUSY = "appState/setBusy",
	SET_CURRENT_NAV = "appState/setCurrentNav",
	SET_AUTHENTICATED = "appState/setAuthenticated",
	SET_NOT_AUTHENTICATED = "appState/setNotAuthenticated",
	SET_GRAPH_DATA = "appState/setGraphData",
}

export type AppStateAction = {
	type: ActionTypes;
	isBusy?: boolean;
	currentNav?: string;
	isAuthenticated?: boolean;
	userId?: string;
	name?: string;
	email?: string;
	userImageUrl?: string;
	graphData?: any;
}

export const setIsBusy = (isBusy: boolean): AppStateAction => ({
	type: ActionTypes.SET_BUSY,
	isBusy,
});

export const setCurrentNav = (currentNav: string): AppStateAction => ({
	type: ActionTypes.SET_CURRENT_NAV,
	currentNav,
});

export const setAuthenticated = (userId: string, name: string, email: string, userImageUrl: string): AppStateAction => ({
	type: ActionTypes.SET_AUTHENTICATED,
	isAuthenticated: true,
	name,
	userId,
	email,
	userImageUrl,
});

export const setNotAuthenticated = (): AppStateAction => ({
	type: ActionTypes.SET_NOT_AUTHENTICATED,
});

export const setGraphData = (graphData: any): AppStateAction => ({
	type: ActionTypes.SET_GRAPH_DATA,
	graphData,
});

