import i18n from "../../i18n";

import { IProduct } from "../../models/IProduct";

import yourBomIcon from "../../assets/StoreIcon.png";
import partsListIcon from "../../assets/partsListEditor.png";
import megaMakeIcon from "../../assets/megaMakeIcon.png";
import properdizerIcon from "../../assets/properdizer.png";
import plm360Icon from "../../assets/plm360.png";
import shapeManagerIcon from "../../assets/shapemanager.png";


export const products: IProduct[] = [
	{
		id: 14,
		name: i18n.t("products.shapeManager.name"),
		summary: i18n.t("products.shapeManager.summary"),
		description: i18n.t("products.shapeManager.description"),
		imageUrl: shapeManagerIcon,
		storeUrl: "https://apps.autodesk.com/INVNTOR/en/Detail/Index?id=8105370772785053506&appLang=en&os=Win64",
		actionButtonContent: i18n.t("products.shapeManager.actionButtonContent"),
		detailsUrl: "/productDetails/14",
		detailsUrlContent: i18n.t("products.shapeManager.detailsContent"),
		reviewAverage: 0,
		reviewCount: 0,
		price: i18n.t("products.shapeManager.price"),
		location: i18n.t("products.shapeManager.location"),
		tags: [
			i18n.t("products.tags.inventor"),
			i18n.t("products.tags.sketch"),
			i18n.t("products.tags.y2021"),
			i18n.t("products.tags.y2022"),
			i18n.t("products.tags.y2023"),
			i18n.t("products.tags.timeSaver"),
		],
		contactLink: "mailto:info@brainbucketsoftware.com",
		galleryImages: [
			{
				id: "01",
				src: "/images/shapemanager/home-screen.png",
				alt: i18n.t("products.shapemanager.homeScreen"),
			  },
			  {
				id: "02",
				src: "/images/shapemanager/settings-screen.png",
				alt: i18n.t("products.shapemanager.settingsScreen"),
			  },
			  {
				id: "03",
				src: "/images/shapemanager/shapemanager-button.png",
				alt: i18n.t("products.shapemanager.commandButton"),
			  },
		]
	},
	{
		id: 13,
		name: i18n.t("products.yourBom.name"),
		summary: i18n.t("products.yourBom.summary"),
		description: i18n.t("products.yourBom.description"),
		imageUrl: yourBomIcon,
		storeUrl: "https://apps.autodesk.com/INVNTOR/en/Detail/Index?id=8935005626641266520&appLang=en&os=Win64",
		actionButtonContent: i18n.t("products.yourBom.actionButtonContent"),
		detailsUrl: "/productDetails/13",
		detailsUrlContent: i18n.t("products.yourBom.detailsContent"),
		reviewAverage: 0,
		reviewCount: 0,
		price: i18n.t("products.yourBom.price"),
		location: i18n.t("products.yourBom.location"),
		tags: [
			i18n.t("products.tags.inventor"),
			i18n.t("products.tags.excel"),
			i18n.t("products.tags.y2020"),
			i18n.t("products.tags.y2021"),
			i18n.t("products.tags.y2022"),
			i18n.t("products.tags.y2023"),
			i18n.t("products.tags.bom"),
			i18n.t("products.tags.awesome"),
		],
		contactLink: "mailto:info@brainbucketsoftware.com",
		galleryImages: [
			{
				id: "01",
				src: "/images/yourBom/ToolbarBomButton.jpg",
				alt: i18n.t("products.yourBom.commandButton"),
			  },
			  {
				id: "02",
				src: "/images/yourBom/B2EMainWindow.jpg",
				alt: i18n.t("products.yourBom.mainWindow"),
			  },
			  {
				id: "03",
				src: "/images/yourBom/B2EConfiguration.jpg",
				alt: i18n.t("products.yourBom.configuration"),
			  },
			  {
				id: "04",
				src: "/images/yourBom/B2EBomOutput.jpg",
				alt: i18n.t("products.yourBom.excelOutput"),
			  },
		]
	},
	{
		id: 4,
		name: i18n.t("products.partsListEditor.name"),
		summary: i18n.t("products.partsListEditor.summary"),
		description: i18n.t("products.partsListEditor.description"),
		imageUrl: partsListIcon,
		storeUrl: "https://apps.autodesk.com/INVNTOR/en/Detail/Index?id=4360675712666976926&appLang=en&os=Win64",
		actionButtonContent: i18n.t("products.partsListEditor.actionButtonContent"),
		detailsUrl: "/productDetails/4",
		detailsUrlContent: i18n.t("products.partsListEditor.detailsContent"),
		reviewAverage: 5,
		reviewCount: 4,
		price: i18n.t("products.partsListEditor.price"),
		location: i18n.t("products.partsListEditor.location"),
		tags: [
			i18n.t("products.tags.inventor"),
			i18n.t("products.tags.y2020"),
			i18n.t("products.tags.y2021"),
			i18n.t("products.tags.y2022"),
			i18n.t("products.tags.y2023"),
			i18n.t("products.tags.bom"),
			i18n.t("products.tags.awesome"),
			i18n.t("products.tags.partsList"),
			i18n.t("products.tags.iProps"),
		],
		contactLink: "mailto:info@brainbucketsoftware.com",
		galleryImages: [
			{
				id: "01",
				src: "/images/partsListEditor/Annotatetabbutton.png",
				alt: i18n.t("products.partsListEditor.annotateTabButton"),
			  },
			  {
				id: "02",
				src: "/images/partsListEditor/EditedValue.png",
				alt: i18n.t("products.partsListEditor.editedValue"),
			  },
			  {
				id: "03",
				src: "/images/partsListEditor/MakeNonStatic.png",
				alt: i18n.t("products.partsListEditor.makeNonStatic"),
			  },
			  {
				id: "04",
				src: "/images/partsListEditor/MakeStatic.png",
				alt: i18n.t("products.partsListEditor.makeStatic"),
			  },
		]
	},
	{
		id: 5,
		name: i18n.t("products.properdizer.name"),
		summary: i18n.t("products.properdizer.summary"),
		description: i18n.t("products.properdizer.description"),
		imageUrl: properdizerIcon,
		storeUrl: "https://apps.autodesk.com/INVNTOR/en/Detail/Index?id=2728360557755977440&appLang=en&os=Win64",
		actionButtonContent: i18n.t("products.properdizer.actionButtonContent"),
		detailsUrl: "/productDetails/5",
		detailsUrlContent: i18n.t("products.properdizer.detailsContent"),
		reviewAverage: 0,
		reviewCount: 0,
		price: i18n.t("products.properdizer.price"),
		location: i18n.t("products.properdizer.location"),
		tags: [
			i18n.t("products.tags.inventor"),
			i18n.t("products.tags.y2020"),
			i18n.t("products.tags.y2021"),
			i18n.t("products.tags.y2022"),
			i18n.t("products.tags.y2023"),
			i18n.t("products.tags.awesome"),
			i18n.t("products.tags.iProps"),
		],
		contactLink: "mailto:info@brainbucketsoftware.com",
		galleryImages: [
			{
				id: "01",
				src: "/images/properdizer/MainWindow.png",
				alt: i18n.t("products.properdizer.mainWindow"),
			  },
			  {
				id: "02",
				src: "/images/properdizer/batchmode.png",
				alt: i18n.t("products.properdizer.batchMode"),
			  },
		]
	},
	{
		id: 8,
		name: i18n.t("products.megaMake.name"),
		summary: i18n.t("products.megaMake.summary"),
		description: i18n.t("products.megaMake.description"),
		imageUrl: megaMakeIcon,
		storeUrl: "https://apps.autodesk.com/INVNTOR/en/Detail/Index?id=2728360557755977440&appLang=en&os=Win64",
		actionButtonContent: i18n.t("products.megaMake.actionButtonContent"),
		detailsUrl: "/productDetails/8",
		detailsUrlContent: i18n.t("products.megaMake.detailsContent"),
		reviewAverage: 0,
		reviewCount: 0,
		price: i18n.t("products.megaMake.price"),
		location: i18n.t("products.megaMake.location"),
		tags: [
			i18n.t("products.tags.inventor"),
			i18n.t("products.tags.y2020"),
			i18n.t("products.tags.y2021"),
			i18n.t("products.tags.y2022"),
			i18n.t("products.tags.y2023"),
			i18n.t("products.tags.awesome"),
			i18n.t("products.tags.iProps"),
			i18n.t("products.tags.excel"),
			i18n.t("products.tags.components"),
			i18n.t("products.tags.solids"),
		],
		contactLink: "mailto:info@brainbucketsoftware.com",
		galleryImages: [
			{
				id: "01",
				src: "/images/mmc/ribbonTab.png",
				alt: i18n.t("products.megaMake.ribbonTab"),
			  },
			  {
				id: "02",
				src: "/images/mmc/CommandMegaMakeComps.png",
				alt: i18n.t("products.megaMake.commandMmc"),
			  },
			  {
				id: "03",
				src: "/images/mmc/export-to-excel.png",
				alt: i18n.t("products.megaMake.commandToExcel"),
			  },
			  {
				id: "04",
				src: "/images/mmc/ipt-with-solids.png",
				alt: i18n.t("products.megaMake.iptWithSolids"),
			  },
			  {
				id: "05",
				src: "/images/mmc/MegaMake.png",
				alt: i18n.t("products.megaMake.megaMake"),
			  },
			  {
				id: "06",
				src: "/images/mmc/excel-names-standard-props.png",
				alt: i18n.t("products.megaMake.excelStdProps"),
			  },
			  {
				id: "07",
				src: "/images/mmc/Excel-User-Props.png",
				alt: i18n.t("products.megaMake.excelUserProps"),
			  },
			  {
				id: "08",
				src: "/images/mmc/AssemblyResult.png",
				alt: i18n.t("products.megaMake.assemblyResult"),
			  },
		]
	},
	{
		id: 3,
		name: i18n.t("products.plm360Inv.name"),
		summary: i18n.t("products.plm360Inv.summary"),
		description: i18n.t("products.plm360Inv.description"),
		imageUrl: plm360Icon,
		storeUrl: "https://apps.autodesk.com/INVNTOR/en/Detail/Index?id=4714606215655045239&appLang=en&os=Win32_64",
		actionButtonContent: i18n.t("products.plm360Inv.actionButtonContent"),
		detailsUrl: "/productDetails/3",
		detailsUrlContent: i18n.t("products.plm360Inv.detailsContent"),
		reviewAverage: 0,
		reviewCount: 0,
		price: i18n.t("products.plm360Inv.price"),
		location: i18n.t("products.plm360Inv.location"),
		tags: [
			i18n.t("products.tags.inventor"),
			i18n.t("products.tags.y2020"),
			i18n.t("products.tags.y2021"),
			i18n.t("products.tags.y2022"),
			i18n.t("products.tags.y2023"),
		],
		contactLink: "mailto:info@brainbucketsoftware.com",
		galleryImages: [
			{
				id: "01",
				src: "/images/plm360/login.png",
				alt: i18n.t("products.plm360Inv.login"),
			  },
			  {
				id: "02",
				src: "/images/plm360/allWorkspaces.png",
				alt: i18n.t("products.plm360Inv.allWorkspaces"),
			  },
			  {
				id: "03",
				src: "/images/plm360/sections.png",
				alt: i18n.t("products.plm360Inv.sections"),
			  },
			  {
				id: "04",
				src: "/images/plm360/workspaceDetails.png",
				alt: i18n.t("products.plm360Inv.workspaceDetails"),
			  },
			  {
				id: "05",
				src: "/images/plm360/workspaceSearch.png",
				alt: i18n.t("products.plm360Inv.workspaceSearch"),
			  },
			  {
				id: "06",
				src: "/images/plm360/itemDetails.png",
				alt: i18n.t("products.plm360Inv.itemDetails"),
			  },
			  {
				id: "07",
				src: "/images/plm360/goToMappedItem.png",
				alt: i18n.t("products.plm360Inv.goToMappedItem"),
			  },
		]
	},
	{
		id: 2,
		name: i18n.t("products.plm360Acad.name"),
		summary: i18n.t("products.plm360Acad.summary"),
		description: i18n.t("products.plm360Acad.description"),
		imageUrl: plm360Icon,
		storeUrl: "https://apps.autodesk.com/ACD/en/Detail/Index?id=8354904996532367790&appLang=en&os=Win32_64",
		actionButtonContent: i18n.t("products.plm360Acad.actionButtonContent"),
		detailsUrl: "/productDetails/2",
		detailsUrlContent: i18n.t("products.plm360Acad.detailsContent"),
		reviewAverage: 0,
		reviewCount: 0,
		price: i18n.t("products.plm360Acad.price"),
		location: i18n.t("products.plm360Acad.location"),
		tags: [
			i18n.t("products.tags.autoCad"),
			i18n.t("products.tags.y2020"),
			i18n.t("products.tags.y2021"),
			i18n.t("products.tags.y2022"),
			i18n.t("products.tags.y2023"),
		],
		contactLink: "mailto:info@brainbucketsoftware.com",
		galleryImages: [
			{
				id: "01",
				src: "/images/plm360/login.png",
				alt: i18n.t("products.plm360Acad.login"),
			  },
			  {
				id: "02",
				src: "/images/plm360/allWorkspaces.png",
				alt: i18n.t("products.plm360Acad.allWorkspaces"),
			  },
			  {
				id: "03",
				src: "/images/plm360/sections.png",
				alt: i18n.t("products.plm360Acad.sections"),
			  },
			  {
				id: "04",
				src: "/images/plm360/workspaceDetails.png",
				alt: i18n.t("products.plm360Acad.workspaceDetails"),
			  },
			  {
				id: "05",
				src: "/images/plm360/workspaceSearch.png",
				alt: i18n.t("products.plm360Acad.workspaceSearch"),
			  },
			  {
				id: "06",
				src: "/images/plm360/itemDetails.png",
				alt: i18n.t("products.plm360Acad.itemDetails"),
			  },
			  {
				id: "07",
				src: "/images/plm360/goToMappedItem.png",
				alt: i18n.t("products.plm360Acad.goToMappedItem"),
			  },
		]
	},
];

