export const footer = {
	twitter: "Twitter",
	facebook: "facebook",
	instagram: "Instagram",
	linkedIn: "LinkedIn",
	github: "GitHub",

	tagLine: "making you productive",
	product: "Product",
	product1: "Your BOM",
	product2: "Parts List Editor",
	product3: "Properdizer",

	legalTitle: "Legal",
	legal1: "Privacy",

	copyRight: `${new Date().getFullYear()} brainbucketsoftware, all rights reserved`,

	subscribeTitle: "Stay up to date",
	emailPlaceholder: "Enter your email",
	subscribeButton: "Subscribe"
};
