import React from "react";
import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Divider,
	IconButton,
	Image,
	Input,
	Stack,
	Text,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Footer = (): JSX.Element => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleNavigateButton = (url: string): void => {
		navigate(url);
	};

	return (
		<Box bg="bg-accent" color="on-accent">
			<Container as="footer" role="contentinfo">
				<Stack
					spacing="8"
					direction={{ base: "column", md: "row" }}
					justify="space-between"
					py={{ base: "12", md: "16" }}
				>
					<Stack spacing={{ base: "6", md: "8" }} align="start">
						<Image src="/images/bbsw-logo.png" />
						<Text color="on-accent-muted">
							{t("footer.tagLine")}
						</Text>
					</Stack>
					<Stack
						direction={{ base: "column-reverse", md: "column", lg: "row" }}
						spacing={{ base: "12", md: "8" }}
					>
						<Stack direction="row" spacing="8">
							<Stack spacing="4" minW="36" flex="1">
								<Text
									fontSize="sm"
									fontWeight="semibold"
									color="on-accent-subtle"
								>
									{t("footer.product")}
								</Text>
								<Stack spacing="3" shouldWrapChildren>
									<Button variant="link-on-accent" onClick={() => handleNavigateButton("/productDetails/13")}>{t("footer.product1")}</Button>
									<Button variant="link-on-accent" onClick={() => handleNavigateButton("/productDetails/4")}>{t("footer.product2")}</Button>
									<Button variant="link-on-accent" onClick={() => handleNavigateButton("/productDetails/5")}>{t("footer.product3")}</Button>
								</Stack>
							</Stack>
							<Stack spacing="4" minW="36" flex="1">
								<Text
									fontSize="sm"
									fontWeight="semibold"
									color="on-accent-subtle"
								>
									{t("footer.legalTitle")}
								</Text>
								<Stack spacing="3" shouldWrapChildren>
									<Button variant="link-on-accent">{t("footer.legal1")}</Button>
								</Stack>
							</Stack>
						</Stack>
						<Stack spacing="4">
							<Text fontSize="sm" fontWeight="semibold" color="on-accent-subtle">
								{t("footer.subscribeTitle")}
							</Text>
							<Stack
								spacing="4"
								direction={{ base: "column", sm: "row" }}
								maxW={{ lg: "360px" }}
							>
								<Input
									placeholder={t("footer.emailPlaceholder")}
									type="email"
									required
									variant="outline-on-accent"
								/>
								<Button variant="primary-on-accent" type="submit" flexShrink={0}>
									{t("footer.subscribeButton")}
								</Button>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
				<Divider borderColor="bg-accent-subtle" />
				<Stack
					pt="8"
					pb="12"
					justify="space-between"
					direction={{ base: "column-reverse", md: "row" }}
					align="center"
				>
					<Text fontSize="sm" color="on-accent-subtle">
						&copy; {t("footer.copyRight")}
					</Text>
					<ButtonGroup variant="ghost-on-accent">
						<IconButton
							as="a"
							href="https://www.linkedin.com/in/michael-ponti/"
							aria-label={t("footer.linkedIn")}
							icon={<FaLinkedin fontSize="1.25rem" />}
						/>
						<IconButton
							as="a"
							href="https://github.com/michaelponti"
							aria-label={t("footer.github")}
							icon={<FaGithub fontSize="1.25rem" />}
						/>
						<IconButton
							as="a"
							href="https://twitter.com/michaelponti1"
							aria-label={t("footer.twitter")}
							icon={<FaTwitter fontSize="1.25rem" />}
						/>
					</ButtonGroup>
				</Stack>
			</Container>
		</Box>
	);
}