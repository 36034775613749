export const home = {
	title: "Home",

	pageHeading: "Productivity Software",
	subHeading: "Specializing in CAD enhancements",
	b2yMainWindow: "Your BOM screen shot",
	properdizer: "Properdizer screen shot",
	partsListEditor: "Parts List Editor screen shot",
	megaScreenShot: "Mega Make Components",
};
