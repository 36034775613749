import React from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "../../components/layout";
import { Guard } from "../../components/guard";

export const UsersPage = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Guard>
			<Layout title={t("about.title")}>
				<div>{t("users.comingSoon")}</div>
			</Layout>
		</Guard>
	);
};
