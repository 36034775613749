import React from "react";
import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	HStack,
	Image,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


import { Sidebar } from "./Sidebar";
import { ToggleButton } from "./ToggleButton";

import { buttons, IButtonInfo } from "./navBarButtons";
import { setCurrentNav, useAppState, useAppStateDispatch } from "../../contexts/appState";
import { t } from "i18next";



export const Navbar = () => {
	const isDesktop = useBreakpointValue({ base: false, lg: true });
	const { isOpen, onToggle, onClose } = useDisclosure();
	const appState = useAppState();
	const appStateDispatch = useAppStateDispatch();
	const navigate = useNavigate();


	const doNavigation = (b: IButtonInfo) => {
		navigate(b.path);
		appStateDispatch(setCurrentNav(b.id));
	}
	
	const renderNavbarButtonGroup = (currentNav: string): JSX.Element => {
		console.log(currentNav);
		return (
			<ButtonGroup variant="ghost-on-accent" spacing="1">
				{
					buttons.map(b => {
						if (b.id === currentNav) {
							return (
								<Button key={b.id} aria-current="page" onClick={() => doNavigation(b)}>
									{b.content}
								</Button>
							);
						}
						
						return (
							<Button key={b.id} onClick={() => doNavigation(b)}>
								{b.content}
							</Button>
						);
					})
				}
			</ButtonGroup>
		);
	};


	return (
		<Box as="nav" bg="bg-accent" color="on-accent">
			<Container py={{ base: "3", lg: "4" }}>
				<Flex justify="space-between">
					<HStack spacing="4">
						<Image src="/images/bbsw-logo.png" />
						{/* <Logo /> */}
						{isDesktop && (renderNavbarButtonGroup(appState.currentNav))}
					</HStack>
					{isDesktop ? (
						<HStack spacing="4">
						</HStack>
					) : (
						<>
							<ToggleButton
								isOpen={isOpen}
								aria-label="Open Menu"
								onClick={onToggle}
							/>
							<Drawer
								isOpen={isOpen}
								placement="left"
								onClose={onClose}
								isFullHeight
								preserveScrollBarGap
								// Only disabled for showcase
								trapFocus={false}
							>
								<DrawerOverlay />
								<DrawerContent>
									<Sidebar />
								</DrawerContent>
							</Drawer>
						</>
					)}
				</Flex>
			</Container>
		</Box>
	);
};
