export const products = {
	title: "Products",

	tags: {
		inventor: "Inventor",
		excel: "Excel",
		y2020: "2020",
		y2021: "2021",
		y2022: "2022",
		y2023: "2023",
		bom: "BOM",
		awesome: "AWESOME",
		solids: "solid bodies",
		components: "components",
		partsList: "Parts List",
		iProps: "iProperties",
		autoCad: "AutoCAD",
		sketch: "Sketch",
		timeSaver: "TIMESAVER",
	},
	shapeManager: {
		name: "Sketch Shape Manager",
		summary: "Sketch block insert tool",
		description: "This app will allow you to centrally store all of your sketch blocks and be able to insert them from these places without having to open the source yourself and copying the sketch block definition to your file.",
		actionButtonContent: "STORE",
		detailsContent: "details",
		price: "$1.00 per month",
		location: "Lower Lonsdale",
		commandButton: "Toolbar button",
		settingsScreen: "Settings Window",
		homeScreen: "Main Window",
	},
	yourBom: {
		name: "Your BOM",
		summary: "Custom Format BOM Reporting Tool",
		description: "Export your BOM From Inventor in your own custom format. Add in properties from your documents for a comprehensive BOM report. Maintain different formats for different downstream users",
		actionButtonContent: "STORE",
		detailsContent: "details",
		price: "$1.50 per month",
		location: "Lower Lonsdale",
		commandButton: "Toolbar button",
		mainWindow: "Main Window",
		configuration: "Report configuration",
		excelOutput: "Excel File Result",
	},
	properdizer: {
		name: "Properdizer",
		summary: "Advanced Property Editor for Inventor",
		description: "Use Properdizer to edit individual properties in your Inventor assembly without having to search for sub items in the model browser. Perform batch editing through out your assembly.",
		actionButtonContent: "STORE",
		detailsContent: "details",
		price: "$2.99",
		location: "Lower Lonsdale",
		mainWindow: "Main Window",
		batchMode: "Batch Mode",
	},
	partsListEditor: {
		name: "Parts List Editor",
		summary: "Fast Editing of Parts List Source",
		description: "Use the parts list editor to edit the values in your parts list. It will trace back to the source of the cell that you want to edit and modify it in the model document itself instead. If desired, can also make cells static.",
		actionButtonContent: "STORE",
		detailsContent: "details",
		price: "$1.99",
		location: "Lower Lonsdale",
		annotateTabButton: "Ribbon button",
		editedValue: "Edit value",
		makeNonStatic: "Make value non-static",
		makeStatic: "Make value static",
	},
	megaMake: {
		name: "Mega Make Components",
		summary: "Export all of your solid bodies to new components with properties set",
		description: "This app will allow you to export all of the solid bodies in your ipt file into a Microsoft Excel spreadsheet. Edit the spreadsheet with a new name for the component. The spreadsheet also has columns for iProperties and you also have the ability to add user properties.",
		actionButtonContent: "STORE",
		detailsContent: "details",
		price: "$2.99",
		location: "Lower Lonsdale",
		ribbonTab: "Ribbon with buttons",
		commandMmc: "Mega Make Components",
		commandToExcel: "Export to Excel",
		iptWithSolids: "Part File With Solids",
		megaMake: "Mega Make Window",
		excelStdProps: "Excel standard iProps",
		excelUserProps: "Excel user iProps",
		assemblyResult: "Generated assembly",
	},
	plm360Inv: {
		name: "PLM360 Attach",
		summary: "Some functionality of Fusion Lifecycle integrated with AutoCAD.",
		description: "This app allows you to view some limited Fusion Lifecycle data from within Inventor. ***Warning*** This app was developed using 'PREVIEW' services from Autodesk and can stop working at any moment.",
		actionButtonContent: "STORE",
		detailsContent: "details",
		price: "FREE",
		location: "Lower Lonsdale",
		login: "Login to tenant",
		allWorkspaces: "Tenant Workspaces",
		sections: "Workspace sections",
		workspaceDetails: "Workspace details",
		workspaceSearch: "Workspace search",
		itemDetails: "Item details",
		goToMappedItem: "Go to mapped item",
	},
	plm360Acad: {
		name: "PLM360 Attach Design",
		summary: "Some functionality of Fusion Lifecycle integrated with AutoCAD.",
		description: "This app allows you to view some limited Fusion Lifecycle data from within AutoCAD. ***Warning*** This app was developed using 'PREVIEW' services from Autodesk and can stop working at any moment.",
		actionButtonContent: "STORE",
		detailsContent: "details",
		price: "FREE",
		location: "Lower Lonsdale",
		login: "Login to tenant",
		allWorkspaces: "Tenant Workspaces",
		sections: "Workspace sections",
		workspaceDetails: "Workspace details",
		workspaceSearch: "Workspace search",
		itemDetails: "Item details",
		goToMappedItem: "Go to mapped item",
	},
};
