import React from "react";

import { Layout } from "../../components/layout";
import { Content } from "./content";


export const HomePage = (): JSX.Element => {
	return (
		<Layout title="home" containerWidth="80%">
			<Content />
		</Layout>
	);
};
