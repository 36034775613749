import React from "react";
import { useTranslation } from "react-i18next";

import { Layout } from "../../components/layout";


export const AboutPage = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Layout title={t("about.title")}>
			<div>
				<div>Coming Soon</div>
			</div>
		</Layout>
	);
};
