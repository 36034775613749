import { about } from "./about";
import { autodeskAppPrivacy } from "./autodeskAppPrivacy";
import { footer } from "./footer";
import { home } from "./home";
import { login } from "./login";
import { productDetails } from "./productDetails";
import { products } from "./products";
import { services } from "./services";
import { users } from "./users";

export const enUS = {
	title: "brainbucketsoftware",

	navHome: "Home",
	navProducts: "Products",
	navServices: "Services",
	navUsers: "Users",
	navAbout: "About",

	loginLink: "Login",
	contactButtonContent: "Contact",

	about,
	autodeskAppPrivacy,
	footer,
	home,
	login,
	productDetails,
	products,
	services,
	users,
};
