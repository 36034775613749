import { AppState } from "./appState";
import { AppStateAction, ActionTypes } from "./appStateActions";


export const initialAppState: AppState = {
	isBusy: false,
	currentNav: "home",
	isAuthenticated: false,
	userId: "chris",
	name: "chris",
	email: "chris@chakra.com",
	userImageUrl: "https://tinyurl.com/yhkm2ek8",
	graphData: undefined,
};

export const appStateReducer = (state: AppState = initialAppState, action: AppStateAction): AppState => {
	switch (action.type) {
		case ActionTypes.SET_BUSY:
			return {
				...state,
				isBusy: action.isBusy || state.isBusy,
			};
		case ActionTypes.SET_CURRENT_NAV:
			return {
				...state,
				currentNav: action.currentNav || state.currentNav,
			};
		case ActionTypes.SET_AUTHENTICATED:
			return {
				...state,
				isAuthenticated: true,
				userId: action.userId || state.userId,
				name: action.name || state.name,
				email: action.email || state.email,
				userImageUrl: action.userImageUrl || state.userImageUrl,
			};
		case ActionTypes.SET_NOT_AUTHENTICATED:
			return {
				...state,
				isAuthenticated: false,
				userId: "",
				name: "",
				email: "",
				userImageUrl: "",
			};
		case ActionTypes.SET_GRAPH_DATA:
			return {
				...state,
				graphData: action.graphData || state.graphData,
			};
		default:
			return state;
	}
};
