import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";


import { Layout } from "../../components/layout";
import { ProductTitle } from "./productTitle";
import { products } from "../products/products";
import { IProduct } from "../../models/IProduct";
import { setCurrentNav, useAppStateDispatch } from "../../contexts/appState";
import { ProductDetails } from "./productDetails";



export const ProductDetailsPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { productId } = useParams();
	const [ id, setId ] = useState(productId);
	const [ details, setDetails ] = useState<IProduct | undefined>(undefined);
	const dispatch = useAppStateDispatch();

	useEffect(() => {
		console.log(id);
		const d = products.find(product => product.id === parseInt(id || "0"));
		dispatch(setCurrentNav("productDetails"));
		setDetails(d);
	}, [ id, dispatch ]);

	console.log(productId);

	return (
		<Layout title={t("productDetails.title")}>
			<Stack>
				<ProductTitle title={details?.name || ""} summary={details?.summary || ""} storeUrl={details?.storeUrl || ""} />
				{
					details && (<ProductDetails {...details} />)
				}
			</Stack>
		</Layout>
	)
};
